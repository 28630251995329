import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/';
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

Vue.config.productionTip = false;
Vue.prototype.$request = axios;

new Vue({
  router,
  render: (h) => h(App),
  created() {
    let project = process.env.VUE_APP_PROJECT;
    if (project) {
      document.querySelector('body').className += ' ' + project;
    }
  },
}).$mount('#app');
