<template>
  <div class="footer">
    <div class="footer-links">
      <div class="footer-link">
          <span>техподдержка</span> <a :href="'mailto:' + email">{{ email }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    email() {
      return process.env.VUE_APP_SUPPORT_EMAIL ? process.env.VUE_APP_SUPPORT_EMAIL : 'info@letique.ru';
    },
  },
};
</script>
<style>
.footer-links {
  justify-content: center;
}
</style>
